import { render, staticRenderFns } from "./AddCreditCard.vue?vue&type=template&id=12d5167a&scoped=true&"
import script from "./AddCreditCard.vue?vue&type=script&lang=js&"
export * from "./AddCreditCard.vue?vue&type=script&lang=js&"
import style0 from "./AddCreditCard.vue?vue&type=style&index=0&id=12d5167a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12d5167a",
  null
  
)

export default component.exports