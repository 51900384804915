<template>
  <div>
    <div class="sub-header-print"></div>
    <letter-head :no-padding="true" :no-border="true" :no-margin="isPrintingForm">
      <div class="printable-title top" v-if="model && request">
      <div class="printable-title-text semi-bold">
        Delivery Checklist #{{ model.id }}
      </div>
      </div>
    </letter-head>
    <page-content v-if="model">
      <div :style="`marginBottom: ${isPrintingForm ? '0' : '18px'}; page-break-inside: avoid;`">
        <div class="main-info" :style="`paddingTop: ${isPrintingForm ? '0' : '20px'}`">
          <div class="main-info--col custom-card" style="margin-right: 24px ;" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
            <div class="main-info--details">
              <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#808285" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="gray" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                Order Date:
              </div>
              <div class="bold-info " :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                {{ $formatDate(model.delivery_date) }}
              </div>
            </div>
            <div class="main-info--details">
              <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" stroke="#808285" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="gray" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                # of People:
              </div>
              <template v-if="request">
                <div class="bold-info" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                  {{ request.people }}
                </div>
              </template>
            </div>
          </div>
          <div class="main-info--col" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
            <template v-if="request">
            <div class="main-info--details bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
              {{ model.request_name }}
            </div>
            </template>
            <div class="main-info--details">
              <div class="gray" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                Invoce #{{ model.invoice_id }}
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="printable-bottom size">
        <el-row :gutter="$rowGutter">
          <el-col :span="8" v-if="pickupAddress">
          <div class="printable-title-text center semi-bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            Pick up Details
          </div>
          </el-col>
          <el-col :span="8">
            <div class="printable-title-text center semi-bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
              Delivery Details
            </div>
          </el-col>
          <el-col :span="8">
          <div class="printable-title-text center semi-bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            Contact & Instructions
          </div>  
          </el-col>
        </el-row>
        <el-row class="indent" :gutter="$rowGutter">
          <el-col :span="8" v-if="pickupAddress">
            <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" style="padding: 0 0 32px 0;">
              <div class="bigger" style="background-color: #f0F6f4; padding-top: 32px; color: #23813E;">
              <div class="detail-title " :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9998 7.99999V16L21.3332 18.6667M29.3332 16C29.3332 23.3638 23.3636 29.3333 15.9998 29.3333C8.63604 29.3333 2.6665 23.3638 2.6665 16C2.6665 8.63619 8.63604 2.66666 15.9998 2.66666C23.3636 2.66666 29.3332 8.63619 29.3332 16Z" stroke="#23813E" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <strong class="semi-bold-600" style="color: #23813E;"> {{ $formatTime(model.delivery_pickup_time) }}</strong>
              </div>
            </div>
            <div style="padding: 0 32px;">
              <div class="secondary">
              <div class="semi-bold-600 " style="color: black;" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                {{ model.vendor_name }}
              </div>
            </div>
            <div class="secondary" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
              {{ pickupAddress.address_line_1 }}
              <div class="secondary" v-if="pickupAddress.address_line_2" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                {{ pickupAddress.address_line_2 }}
              </div>
              <div class="secondary" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                {{ pickupAddress.city }}, {{ pickupAddress.state }} {{ pickupAddress.zip_code }}
              </div>
            </div>

            </div>
          </div>
          </el-col>
          <el-col :span="8">
            <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" style="padding: 0 0 32px 0;">
              <div class="bigger" style="background-color: #f0F6f4; padding-top: 32px; color: #23813E;">
                <div class="detail-title" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9998 7.99999V16L21.3332 18.6667M29.3332 16C29.3332 23.3638 23.3636 29.3333 15.9998 29.3333C8.63604 29.3333 2.6665 23.3638 2.6665 16C2.6665 8.63619 8.63604 2.66666 15.9998 2.66666C23.3636 2.66666 29.3332 8.63619 29.3332 16Z" stroke="#23813E" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <strong class="semi-bold-600" style="color: #23813E;"> {{ $formatTime(model.delivery_time) }}</strong>
              </div>
            </div>
            <template v-if="deliveryAddress">
              <div style="padding: 0 32px;">
              <div class="secondary" v-if="deliveryAddress.name">
                <div class="semi-bold-600 " style="color: black;" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                {{ deliveryAddress.name }}
                </div>
              </div>
              <div class="secondary">
                <div class="semi-bold-600 " style="color: black;" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                  {{ deliveryAddress.address_line_1 }}
                </div>
                <template v-if="request">
                  <div class="secondary" v-if="request.suite && request" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                    {{ request.suite }}
                  </div>
                </template>
                <div class="secondary" v-if="deliveryAddress.address_line_2 && (request && !request.suite)" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                  {{ deliveryAddress.address_line_2 }}
                </div>
                <div class="secondary" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
                  {{ deliveryAddress.city }}, {{ deliveryAddress.state }}
                  {{ deliveryAddress.zip_code }}
                </div>
              </div>
              </div>
            </template>
          </div>
          </el-col>
          <el-col :span="8" v-if="model">
            <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" style="padding-top: 8px; padding: 0 0 32px 0;">
            <small>
              <div class="bigger" style="background-color: #f0F6f4; padding-top: 7px; color: #23813E;">
                <strong style="color: #23813E;" class="semi-bold-600">
                <div class="detail-phone" v-if="contact" :style="`fontSize: ${isPrintingForm ? '8px' : '16px'}`">
                  <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 16H7.5C6.10444 16 5.40665 16 4.83886 16.1722C3.56045 16.56 2.56004 17.5605 2.17224 18.8389C2 19.4067 2 20.1044 2 21.5M16 18.5L18 20.5L22 16.5M14.5 8C14.5 10.4853 12.4853 12.5 10 12.5C7.51472 12.5 5.5 10.4853 5.5 8C5.5 5.51472 7.51472 3.5 10 3.5C12.4853 3.5 14.5 5.51472 14.5 8Z" stroke="#23813E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ contact.salutation }} {{ contact.first_name }}
                {{ contact.last_name }}
                </div>
                </strong>
                <div class="detail-phone semi-bold-600 " :style="`fontSize: ${isPrintingForm ? '8px' : '16px'}`">
                  <svg :width="`${isPrintingForm ? '12' : '24'}`" :height="`${isPrintingForm ? '12' : '24'}`" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5497 6C15.5264 6.19057 16.424 6.66826 17.1277 7.37194C17.8314 8.07561 18.3091 8.97326 18.4997 9.95M14.5497 2C16.5789 2.22544 18.4713 3.13417 19.9159 4.57701C21.3606 6.01984 22.2717 7.91101 22.4997 9.94M10.7266 13.8631C9.52506 12.6615 8.57627 11.3028 7.88028 9.85323C7.82041 9.72854 7.79048 9.66619 7.76748 9.5873C7.68576 9.30695 7.74446 8.96269 7.91447 8.72526C7.96231 8.65845 8.01947 8.60129 8.13378 8.48698C8.48338 8.13737 8.65819 7.96257 8.77247 7.78679C9.20347 7.1239 9.20347 6.26932 8.77247 5.60643C8.65819 5.43065 8.48338 5.25585 8.13378 4.90624L7.93891 4.71137C7.40747 4.17993 7.14174 3.91421 6.85636 3.76987C6.2888 3.4828 5.61854 3.4828 5.05098 3.76987C4.7656 3.91421 4.49987 4.17993 3.96843 4.71137L3.8108 4.86901C3.28117 5.39863 3.01636 5.66344 2.81411 6.02348C2.58969 6.42298 2.42833 7.04347 2.4297 7.5017C2.43092 7.91464 2.51103 8.19687 2.67124 8.76131C3.53221 11.7947 5.15668 14.6571 7.54466 17.045C9.93264 19.433 12.795 21.0575 15.8284 21.9185C16.3928 22.0787 16.6751 22.1588 17.088 22.16C17.5462 22.1614 18.1667 22 18.5662 21.7756C18.9263 21.5733 19.1911 21.3085 19.7207 20.7789L19.8783 20.6213C20.4098 20.0898 20.6755 19.8241 20.8198 19.5387C21.1069 18.9712 21.1069 18.3009 20.8198 17.7333C20.6755 17.448 20.4098 17.1822 19.8783 16.6508L19.6835 16.4559C19.3339 16.1063 19.1591 15.9315 18.9833 15.8172C18.3204 15.3862 17.4658 15.3862 16.8029 15.8172C16.6271 15.9315 16.4523 16.1063 16.1027 16.4559C15.9884 16.5702 15.9313 16.6274 15.8644 16.6752C15.627 16.8453 15.2828 16.904 15.0024 16.8222C14.9235 16.7992 14.8612 16.7693 14.7365 16.7094C13.2869 16.0134 11.9282 15.0646 10.7266 13.8631Z" stroke="#23813E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <template v-if="contact">
                    <div style="color: #23813E;">
                      {{ modifiedPhoneNumber(contact.phone_number) }}
                    </div>
                  </template>
                </div>
              </div>
            </small>
            <div :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`" class="" style="padding: 0 32px;">
              {{ model.customer_account_name }}
              <br />
            </div>
          </div>  
          </el-col>
        </el-row>
      </div>
      <br>
      <div class="printable-title-text center semi-bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}; marginBottom: ${isPrintingForm ? '0px' : '16px'}; marginTop: ${isPrintingForm ? '5px' : '10px'};`">
        Important Instructions
      </div>
      <div>
        <div class="notes" style="margin-top: 1.25rem;" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div v-if="model.purchase_order_notes && model.purchase_order_notes.length >= 1 && model.purchase_order_notes[0].trim() !== ''">
          <div class="purchase" v-for="purchase in model.purchase_order_notes" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ purchase }}
          </div>
        </div>
        <em v-else :style="`fontSize: ${isPrintingForm ? '10px' : '18px'};`">(None)</em>
      </div>
      </div>
      <div style="page-break-inside: avoid;">
        <br>
      <div class="printable-title-text semi-bold" style="margin-bottom: 16px;" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}; marginTop: ${isPrintingForm ? '10px' : '20px'};`">
        Client Orders
      </div>  
      <!-- <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`"> -->
      <el-table :header-cell-style="{ background: '#F0F6F4', color: '#115F43' }" v-if="model != null" :data="orderItems" style="width: 100%; border-radius: 18px 18px 0 0; padding: 20px; border: 1px solid #F2F2F2" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'};`">
            <el-table-column prop="quantity" label="QTY" :width="isPrintingForm ? '80' : '85'"><template slot-scope="scope">
                    <div class="printable-checkbox"></div><span>{{ scope.row.quantity }}</span>
                </template></el-table-column>
            <el-table-column prop="name" label="Name" :width="isPrintingForm ? 200 : mainWidth"><template slot-scope="scope">
                    <div class="base-price"><span>{{ scope.row.name }}</span><span class="value">({{ $formatCents(scope.row.base_price_cents) }})</span></div>
                </template></el-table-column>
            <el-table-column prop="item_price" label="Item Price" :width="isPrintingForm ? 160 : 105"><template slot-scope="scope"><span>{{ $formatCents(scope.row.base_price_cents * scope.row.quantity) }}</span></template></el-table-column>
            <el-table-column prop="description" label="Delivery Notes" :width="isPrintingForm ? 'auto' : mainWidth"><template slot-scope="scope"><span>
                        <div class="description" :style="{maxWidth: isPrintingForm ? '30%' : '80%', overflowWrap: 'anywhere'}">{{ scope.row.product_notes }}</div>
                    </span></template></el-table-column>
      </el-table>
      <!-- </div> -->
      </div>
      <div style="page-break-inside: avoid;">
        <br>
      <div class="printable-title-text semi-bold" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}; marginTop: ${isPrintingForm ? '6px' : '12px'};`" style="margin-bottom: 16px; margin-top: 48px;">
        Please Double Check
      </div>
      <div style="margin-top: 1.25rem;" class="notes" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div class="pls-note-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
            Have order <strong class="bold">ready</strong> on time
          </div>
        </div>
        <div class="pls-note-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
        <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        <div>
          Make sure <strong class="bold">contents &amp; quantities</strong> are correct
        </div>
        </div>
        <div class="pls-note-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
          <strong class="bold">Do not charge tax</strong> &amp; Apply SimpleCater Discount
          </div>
        </div>
        <div class="pls-note-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
            Provide Utensils, Plates &amp; Servingware (if requested)
          </div>
        </div>
      </div>
      </div>
      <br>
      <div :style="`marginTop: ${isPrintingForm ? '10px' : '20px'}; page-break-inside: avoid;`" class="check-footer" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <div class="check-footer-contact-us">
            Contact Us
          </div>
          <div class="check-footer-info" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            for any issues, questions or concerns
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1737 11.8043C12.1017 13.7372 13.3667 15.5487 14.9689 17.1508C16.571 18.7529 18.3825 20.018 20.3153 20.946C20.4816 21.0258 20.5647 21.0657 20.6699 21.0963C21.0437 21.2053 21.5027 21.127 21.8193 20.9004C21.9083 20.8366 21.9846 20.7604 22.137 20.6079C22.6031 20.1418 22.8362 19.9087 23.0706 19.7564C23.9544 19.1817 25.0938 19.1817 25.9777 19.7564C26.2121 19.9087 26.4451 20.1418 26.9113 20.608L27.1711 20.8678C27.8797 21.5764 28.234 21.9307 28.4265 22.3112C28.8092 23.0679 28.8092 23.9616 28.4265 24.7184C28.234 25.0989 27.8797 25.4532 27.1711 26.1617L26.9609 26.3719C26.2548 27.0781 25.9017 27.4312 25.4216 27.7008C24.889 28.0001 24.0616 28.2152 23.4507 28.2134C22.9001 28.2118 22.5238 28.1049 21.7712 27.8913C17.7267 26.7434 13.9102 24.5774 10.7262 21.3934C7.54224 18.2095 5.37627 14.393 4.22831 10.3485C4.0147 9.59586 3.9079 9.21956 3.90626 8.66897C3.90444 8.05801 4.11959 7.23068 4.41882 6.69801C4.68848 6.21797 5.04157 5.86489 5.74773 5.15872L5.95791 4.94854C6.6665 4.23995 7.02079 3.88566 7.4013 3.6932C8.15805 3.31044 9.05174 3.31044 9.80848 3.6932C10.189 3.88566 10.5433 4.23995 11.2519 4.94854L11.5117 5.20837C11.9778 5.67451 12.2109 5.90758 12.3633 6.14195C12.938 7.0258 12.938 8.16525 12.3633 9.0491C12.2109 9.28347 11.9778 9.51654 11.5117 9.98268C11.3593 10.1351 11.2831 10.2113 11.2193 10.3004C10.9926 10.617 10.9143 11.076 11.0233 11.4498C11.054 11.555 11.0939 11.6381 11.1737 11.8043Z" stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="check-footer-info">
            800 310-0650
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.1665 9.33337L14.0531 16.954C14.9346 17.5711 15.3754 17.8796 15.8549 17.9991C16.2784 18.1047 16.7213 18.1047 17.1448 17.9991C17.6243 17.8796 18.065 17.5711 18.9466 16.954L29.8332 9.33337M9.5665 26.6667H23.4332C25.6734 26.6667 26.7935 26.6667 27.6491 26.2307C28.4018 25.8472 29.0137 25.2353 29.3972 24.4827C29.8332 23.627 29.8332 22.5069 29.8332 20.2667V11.7334C29.8332 9.49316 29.8332 8.37306 29.3972 7.51741C29.0137 6.76476 28.4018 6.15284 27.6491 5.76935C26.7935 5.33337 25.6734 5.33337 23.4332 5.33337H9.5665C7.32629 5.33337 6.20619 5.33337 5.35054 5.76935C4.59789 6.15284 3.98597 6.76476 3.60248 7.51741C3.1665 8.37306 3.1665 9.49316 3.1665 11.7334V20.2667C3.1665 22.5069 3.1665 23.627 3.60248 24.4827C3.98597 25.2353 4.59789 25.8472 5.35054 26.2307C6.20619 26.6667 7.32629 26.6667 9.5665 26.6667Z" stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="check-footer-info">
          info@simplecater.com
          </div>
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import _ from "underscore";
import mainWidth from "@/mixins/mainWidth";
//import isPrintingForm from "@/mixins/mainWidth"
import LetterHead from "./LetterHead.vue";
import PageContent from "./PageContent.vue";
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'
import UserPreferences from "@/user/blocks/UserPreferences.vue";

export default {
  components: { LetterHead, PageContent, UserPreferences },

  mixins: [mainWidth, modifyPhoneNumber, ],
  //isPrintingForm

  data() {
    return {
      model: null,
      address: null,
      pickupAddress: null,
      deliveryAddress: null,
      request: null,
      contact: null,
      isPrintingForm: false
    };
  },
  props: {
    id: { required: true }
  },
  methods: {
    async loadPickUpAddress(pickupAddressId) {
      try {
        this.pickupAddress = (
          await this.$simplecater.get(`/addresses/${pickupAddressId}`)
        ).data.data;
      } catch {
        this.$message.error("Error loading the address information");
      }
    },
    async loadDeliveryAddress(deliveryAddressId) {
      try {
        this.deliveryAddress = (
          await this.$simplecater.get(`/addresses/${deliveryAddressId}`)
        ).data.data;
      } catch {
        this.$message.error("Error loading the address information");
      }
    },
    async getData() {
      try {
        this.model = (
          await this.$simplecater.get(`/orders/${this.id}`)
        ).data.data;
        const {
          delivery_address_id,
          pickup_address_id,
          delivery_time,
          date: delivery_date,
          notes
        } = (
          await this.$simplecater.get(`/deliveries/${this.model.delivery_id}`)
        ).data.data;
        this.model = {
          ...this.model,
          purchase_order_notes: this.model.purchase_order_notes.split('\n'),
          pickup_address_id,
          delivery_address_id,
          delivery_time,
          delivery_date,
          notes
        };
        await Promise.all([
          this.model.pickup_address_id &&
            this.loadPickUpAddress(this.model.pickup_address_id),
          this.model.request_address_id &&
            this.loadDeliveryAddress(this.model.request_address_id)
        ]);

        // Request
        try {
          this.request = (
            await this.$simplecater.get(`/requests/${this.model.request_id}`)
          ).data.data;
        } catch {
          this.$message.error("Error loading the request information");
        }
        try {
          this.contact = (
            await this.$simplecater.get(`/contacts/${this.request.contact_id}`)
          ).data.data;
        } catch {
          this.$message.error("Error loading the contact information");
        }
      } catch {
        this.$message.error("Error loading the data");
      }
    }
  },
  computed: {
    orderItems() {
      return this.model
        ? _.filter(this.model.items, x => x.type === "PRODUCT")
        : [];
    }
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped lang="sass">
.center
  display: flex
  justify-content: center
.purchase
  display: flex
  justify-content: flex-start
  align-items: center
  gap: 8px
  margin-bottom: 6px
.header
  border: none
.size
  margin: 0 0 4mm

.printable-checkbox
  display: inline-block
  width: 4.5mm
  height: 4.5mm
  margin-right: 2mm
  border: 1px solid #333
  vertical-align: sub

.signature-line
  border-top: 1px solid black
  margin: 30mm 0 0
  width: 80mm
  font-size: 0.7rem
  color: #888
  padding: 0.2rem 0 0

.bigger
  font-size: 1rem
  border-bottom: 1px solid #EBEBE4
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin-bottom: 18px

.indent
  margin-top: 3mm
  display: flex
  align-items: stretch

.main-text
  font-family: Inter
  font-size: 24px
  font-style: normal
  font-weight: 500
  line-height: 24px
  letter-spacing: -0.72px

.main-info
  display: flex
  justify-content: space-between
  padding: 20px 0 
  &--col
    display: flex
    flex-direction: column
    gap: 10px
    flex-grow: 1
  &--details
    display: flex
    flex-direction: row
    gap: .75rem
    align-items: center
    font-weight: 500
.gray
  color: #70706B

.bold
  font-weight: 600

.detail-title
  display: flex
  align-items: center
  font-size: 1rem
  gap: 8px
  margin-bottom: 18px

.detail-phone
  display: flex
  align-items: center
  gap: 8px
  margin-bottom: 9px

.info-card
  height: 100%
  -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
  -webkit-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1)

.orders-title
  margin-top: 3.75rem

.notes
  font-size: 1.25rem

.check-footer
  font-size: 1.25rem
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  gap: 0px
  background-color: red
  &-item
    display: flex
    align-items: center
    gap: 8px
    margin-bottom: 1.125rem
    justify-content: center
  &-contact-us
    font-weight: 600
  &-info
      color: white  

.semi-bold
  font-weight: 500

.semi-bold-600 
  font-weight: 600
  color: #000       
</style>
