<template>
<el-card>
<div ref="table">
    <div>
        <el-row :gutter="$rowGutter">
            <el-col :span="12">
                <div class="products-from">{{title}}
                <div class="po-number" v-if="customer_po">PO# {{customer_po}}</div>
                </div>
                 <div class="price-info">
                    <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 8.25V11.25M13.5 6.75V9.75M12.75 3C14.5865 3 15.5799 3.28107 16.0741 3.49908C16.1399 3.52812 16.1728 3.54263 16.2678 3.63328C16.3247 3.68761 16.4287 3.84705 16.4554 3.92107C16.5 4.04455 16.5 4.11205 16.5 4.24706V12.3084C16.5 12.9899 16.5 13.3307 16.3978 13.5059C16.2938 13.6841 16.1936 13.7669 15.999 13.8354C15.8076 13.9027 15.4215 13.8285 14.6491 13.6801C14.1085 13.5762 13.4674 13.5 12.75 13.5C10.5 13.5 8.25 15 5.25 15C3.41347 15 2.42015 14.7189 1.92591 14.5009C1.86009 14.4719 1.82718 14.4574 1.7322 14.3667C1.67526 14.3124 1.57134 14.153 1.5446 14.0789C1.5 13.9554 1.5 13.8879 1.5 13.7529L1.5 5.69164C1.5 5.01006 1.5 4.66928 1.60221 4.49411C1.70618 4.31592 1.80644 4.23309 2.00104 4.16461C2.19235 4.09729 2.57853 4.17149 3.35087 4.31989C3.89146 4.42376 4.53261 4.5 5.25 4.5C7.5 4.5 9.75 3 12.75 3ZM10.875 9C10.875 10.0355 10.0355 10.875 9 10.875C7.96447 10.875 7.125 10.0355 7.125 9C7.125 7.96447 7.96447 7.125 9 7.125C10.0355 7.125 10.875 7.96447 10.875 9Z" stroke="#2BB673" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                    {{ $formatCents(pricePerPerson) }}/price per person - 
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3334 14C13.3334 13.0696 13.3334 12.6044 13.2186 12.2259C12.9601 11.3736 12.2931 10.7067 11.4408 10.4482C11.0623 10.3333 10.5971 10.3333 9.66675 10.3333H6.33342C5.40304 10.3333 4.93785 10.3333 4.55932 10.4482C3.70705 10.7067 3.04011 11.3736 2.78157 12.2259C2.66675 12.6044 2.66675 13.0696 2.66675 14M11.0001 5C11.0001 6.65685 9.65694 8 8.00008 8C6.34323 8 5.00008 6.65685 5.00008 5C5.00008 3.34315 6.34323 2 8.00008 2C9.65694 2 11.0001 3.34315 11.0001 5Z" stroke="#123F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{people}} people
                </div>
                <!-- <div class="tax-info gray">
                    Excludes Tax & Delivery
                </div> -->
            </el-col>
            <el-col :span="12" class="print-version">
                <el-button  type="text" @click="$emit('onPrintClick')">
                    <div class="print-btn">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.6665 5.2C2.6665 4.0799 2.6665 3.51984 2.88449 3.09202C3.07624 2.71569 3.3822 2.40973 3.75852 2.21799C4.18635 2 4.7464 2 5.8665 2H10.1332C11.2533 2 11.8133 2 12.2412 2.21799C12.6175 2.40973 12.9234 2.71569 13.1152 3.09202C13.3332 3.51984 13.3332 4.0799 13.3332 5.2V14L11.4998 12.6667L9.83317 14L7.99984 12.6667L6.1665 14L4.49984 12.6667L2.6665 14V5.2Z" stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Printable version
                    </div>
                </el-button>
                <br>
                <el-button type="text" @click="getItemsReports" :loading="submitting">
                    <div>Download CSV</div>
                </el-button>
            </el-col>
        </el-row>
        <div class="user-custome-estimates-table">
             <table class="user-custome-table" >
                <thead>
                <tr>
                <th class="left-text">Item</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
                </tr>
            </thead>
            <tbody>
            <tr class="user-custome-table-row" v-for="(item,i) in data" :key="i">
                <td class="user-custome-table-item left-text">
                    <div class="pos-name">{{item.name}}</div>
                    <div class="secondary" v-if="item.type == 'PRODUCT'">{{item.product_description}}</div>
                    <div class="secondary serves-count" v-if="item.serves">Serves: {{item.serves}}</div>
                </td>
                <td>
                    <div class="td-content-with-icon">
                    {{ item.quantity }}
                    </div>
                </td> 
                <td>
                    <div class="td-content-with-icon">
                        {{ $formatCents(item.sale_price_cents) }}
                    </div>
                </td> 
                <td>
                    <div class="td-content-with-icon">
                        {{ item.type == 'POINTS' ? '-' : '' }}{{ $formatCents(item.sale_price_cents*item.quantity) }}
                    </div>    
                </td> 
            </tr>
            <tr class="user-custome-table-row">
                <td class="user-custome-table-item left-text">
                    <div class="sub-total secondary-text">Subtotal</div>
                    <div class="sub-total secondary-text">Tax</div>
                    <div class="secondary-text" v-if="tips && tips > 0">Driver Tip</div>
                </td>
                <td></td>
                <td></td> 
                <td>
                    <div class="sub-total sub-total-price">{{ $formatCents(subtotalCents) }}</div>
                    <div class="sub-total sub-total-price">{{ $formatCents(tax) }}</div>
                    <div class="sub-total-price" v-if="tips && tips > 0">{{ $formatCents(tips) }}</div>
                </td> 
            </tr>
              <tr class="user-custome-table-row">
                <td class="user-custome-table-item left-text non-border">
                    <div>Total</div>
                </td>
                <td class="non-border"></td>
                <td class="non-border"></td> 
                <td class="non-border">
                    <div>{{ $formatCents(totalCents) }}</div>
                </td> 
            </tr>
        </tbody>
        </table>
        </div>
        <br />
    </div>
</div>
</el-card>
</template>

<script>
export default {
    data() {
        return {
            showChangesPopUp: false,
            showRejectPopUp: false,
            changeText: '',
            rejectText: '',
            submitting: false
        }
    },

    props: {
        data: {
            require: true
        },
        order_id: {
            require: false
        },
        title: {
            require: true
        },
        subtotalCents: {
            require: false
        },
        totalCents: {
            require: false
        },
        tax: {
            require: false
        },
        tips: {
            require: false
        },
        customer_po: {
            require: false
        },
        pricePerPerson: {
            require: false
        },
        people: {
            require: false
        }
    },

    methods: {
        getItemsReports() {
            this.submitting = true;
            this.$simplecater.get(`/orders/${this.order_id}/csv_report`).then(r => {
                this.submitting = false;

                if (r.status === 204) {
                    this.$message.warning('No data for this order');
                } else {
                    this.downloadCsv(r.data);
                }
            }).catch(() => {
                this.$message.error('Failed loading data');
            })
        },
        downloadCsv(data) {
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
            hiddenElement.target = '_blank';
            hiddenElement.download = `items-from-order-${this.order_id}-vendor-${this.title}.csv`;
            hiddenElement.click();
        }
    }
}
</script>

<style scoped lang="sass">
  .estimate-rejected
    color: #D92D20
    text-align: center
    margin-top: 2rem
    font-size: 1.125rem
  .change-pop-text
    margin-bottom: 2rem
  .change-pop-up-footer
    display: flex
    gap: 2rem
    margin-top: 2rem
  .numeric-summary
    width: 100%
    display: flex
    & > div
      flex: 0 0 50%
  .status-wrapper
    margin: 0 0 0.5rem
  .secondary
    font-size: 12px
    font-style: italic
    white-space: pre-line
    word-break: break-word

  .print-version
    text-align: right

  .products-from
     display: flex
     border-bottom: none
     gap: .5rem
     font-size: 20px
     color: black
     margin-left: 18px
  .price-info
     display: flex
     align-items: center
     gap: .375rem
     font-size: .875rem
     margin-left: 1rem
     width: 100%
  .print-btn
    display: flex
    align-items: center
    gap: .5rem
    color: #125F43
  .user-custome-table
    margin-top: 1.5rem
    width: 100%
    text-align: center
    th, td
        padding: 1rem
    &-item
        max-width: 20vw
    thead
        background: #FAFAF5
        height: 2.625rem
        border: none
        font-size: .75rem
        font-weight: 500
        color: #70706B
    .left-text
        text-align: left
    td
        border-bottom: 1px solid #EBEBE4
    .non-border
        border-bottom: none
    .td-content-with-icon
        display: flex
        align-items: center
        justify-content: center
        gap: .375rem
    .pos-name
        font-size: 1.25rem
        margin-bottom: .375rem
        overflow-wrap: break-word
    .sub-total
        margin-bottom: .75rem
    .secondary-text
        color: #70706B   
        font-size: 14px
    .sub-total-price
        font-size: 14px
 
  .submit-btn
        display: flex
        align-items: center
        gap: .5rem
  .reject-btn
        display: flex
        align-items: center
        gap: .5rem
        color: #D92D20
        cursor: pointer
  .estimate-footer
        display: flex
        justify-content: space-between
  .estimate-req-sub
        display: flex
        gap: 1.25rem

  .po-number
    color: #EB5B13
    margin-left: .5rem
    font-size: 14px
    display: flex
    align-items: center

</style>