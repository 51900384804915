<template>
  <div class="add-credit-card">
    <el-form :model="form" :rules="rules" ref="form" label-width="130px" size="medium">
      <el-form-item id="card-holder-name" label="Name On Card" prop="cardHolderName">
        <el-input v-model="form.cardHolderName" placeholder="Enter a name on this card"></el-input>
      </el-form-item>

      <el-form-item id="card-details" label="Card Details" class="is-required">
        <div id="card-element" class="stripe-element"></div>
        <div id="card-errors" class="error-message" role="alert"></div>
      </el-form-item>

      <div id="buttons-section">
        <el-button @click="resetForm" id="stripe-reset-button">Reset</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading" id="stripe-submit-button">Add Card</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AddCreditCard',
  data() {
    return {
      form: {
        cardHolderName: '',
      },
      stripe: null,
      card: null,
      loading: false,
      rules: {
        cardHolderName: [
          { min: 3, message: 'Name must be at least 3 characters', trigger: 'blur' },
          { max: 50, message: 'Name must be no more than 50 characters', trigger: 'blur' },
          { required: true, message: 'Please enter name on card', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // Initialize Stripe with the key from environment variables
    if (window.Stripe) {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY)
    } else {
      console.error('Stripe.js not loaded')
    }
  },
  mounted() {
    if (!this.stripe) {
      console.error('Stripe not initialized')
      return
    }
    
    // Create card element
    const elements = this.stripe.elements()
    
    // Create card element
    this.card = elements.create('card', {
      style: {
        base: {
          fontSize: '16px',
          color: '#32325d',
          fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
    })

    // Mount the card element
    this.card.mount('#card-element')

    // Handle real-time validation errors
    // this.card.addEventListener('change', (event) => {
    //   const displayError = document.getElementById('card-errors')
    //   if (event.error) {
    //     displayError.textContent = event.error.message
    //   } else {
    //     displayError.textContent = ''
    //   }
    // })
  },
  methods: {
    async submitForm() {
      try {
        await this.$refs.form.validate()
        this.loading = true
        
        // Create a token with metadata
        const { token, error } = await this.stripe.createToken(this.card, {
          name: this.form.cardHolderName,
        })
        
        if (error) {
          // const errorElement = document.getElementById('card-errors')
          // errorElement.textContent = error.message
          this.loading = false
          return
        }

        // Emit the token and card name to parent component
        this.$emit('card-added', {
          token: token.id,
          cardHolderName: this.form.cardHolderName,
          last4: token.card.last4,
          brand: token.card.brand
        })

        // Reset form
        this.resetForm()
      } catch (error) {
        console.error('Form validation failed:', error)
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$refs.form.resetFields()
      this.card.clear()
    }
  },
  beforeDestroy() {
    if (this.card) {
      this.card.destroy()
    }
  }
}
</script>

<style lang="sass" scoped>
.stripe-element
  border-radius: 0.75rem !important
  border: 1px solid #EBEBE4 !important
  
.add-credit-card 
  max-width: 700px
  margin: 0 auto
  padding: 20px

  .stripe-element
    padding: 10px
    border: 1px solid #dcdfe6
    border-radius: 4px
    background-color: white
    
    &:focus
      outline: none
      border-color: #409eff

  .error-message
    color: #fa755a
    font-size: 13px
    margin-top: 8px
    min-height: 20px
  
  #stripe-reset-button
    height: 3rem
    background: #FFFFFF
    padding: 0.75rem 1rem
    color: black
    border-radius: 12px
    border: 1px solid #EBEBE4
    font-weight: 500
    font-size: 1rem
    cursor: pointer
    max-height: 2.5rem

  #stripe-submit-button
    height: 3rem
    max-height: 2.5rem
    background: #125F43
    gap: 8px
    padding: 0.75rem 0.75rem 0.75rem 1rem
    color: white
    border-radius: 12px
    border: none
    font-weight: 500
    font-size: 1rem
    cursor: pointer

  #buttons-section
    display: flex
    flex-direction: row
    justify-content: center
    margin: 0
</style>
