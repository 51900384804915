<template>
  <div class="items-builder">
    <el-table :data="nonTaxItems">
      <el-table-column label="Qty" width="105px">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.quantity"
            :min="1"
            size="mini"
            v-if="hasVariableQuantity(scope.row.type)">
          </el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.name"
            v-if="scope.row.type == 'CUSTOM'"
            size="mini"
          >
          </el-input>
          <template v-else
            >{{ scope.row.name }} &nbsp;<i
              class="mat-icon"
              v-if="scope.row.type == 'DELIVERY'">motorcycle</i>
            </template>
          </template>
      </el-table-column>
      <el-table-column label="Unit price" width="115px"
        ><template slot-scope="scope"
          ><el-input
            v-if="scope.row.type == 'CUSTOM'"
            v-model="customPrice"
            size="mini"
            @blur="blurCustom"
            ><template slot="prepend">$</template></el-input
          ><template v-else>{{
            $formatCents(scope.row.sale_price_cents || 0)
          }}</template></template
        >
      </el-table-column>
      <el-table-column label="Price" width="95px"
        ><template slot-scope="scope">{{
          $formatCents((scope.row.sale_price_cents || 0) * scope.row.quantity)
        }}</template></el-table-column
      >
    </el-table>
    <div class="tips-block-staff" v-if="nonTaxItems.some(i => i.type === 'DELIVERY')">
      Driver Tip:
      <tip-block @tips-add="addTips($event)" :total="tipsTotalPrice" :initialValue="fullOrder ? fullOrder.tips : 0"/>
      {{  $formatCents(tips || 0) }}
    </div>
    <div class="numeric-summary">
      <div class="label">Subtotal</div>
      <div class="value">{{ $formatCents(subtotalCents) }}</div>
    </div>
    <div class="numeric-summary">
      <div class="label">Tax | {{ (taxRate * 100).toFixed(1) }}%</div>
      <div class="value">{{ $formatCents(taxValue) }}</div>
    </div>
    <div class="numeric-summary">
      <div class="label">Total</div>
      <div class="value">{{ $formatCents(totalCents + (tips || 0)) }}</div>
    </div>
  </div>
</template>

<style lang="sass">
.items-builder
  .el-input-number--mini
    max-width: 95px
  .el-input-group__prepend
    // this is the dollar sign
    padding: 0 8px
  .el-input-group--prepend
    // this is the whole group with dollar + input included
    max-width: 115px
.tips-block-staff
  display: flex
  align-items: center
  gap: 20px
  padding: 20px
</style>

<script>
import _ from "underscore";
import { DELIVERY_DEFAULT } from "../../data/utils";
import TipBlock from '../../user/blocks/TipBlock.vue'

function defaultItems() {
  return {
    TAX: {
      type: "TAX",
      quantity: 1,
      checked: true,
      sale_price_cents: 0,
      name: "Tax",
      position: 0
    }
  };
}



function data() {
  return {
    indexedItems: defaultItems(),
    endWithDot: false,
    startFromMinus: false,
    onlyMinus: false,
    tips: 0
  };
}

function created() {
  // TODO add all this event handlers at the parent?
  // that way hot reload doesn't break things
  // because these events are not replicated after the reload
  // but if they were data on the parent the value would remain after
  // hot reloading hence removing the issue
  this.eventBus.$on("add_item", this.addItem);
  this.eventBus.$on("remove_item", this.removeItem);
  this.eventBus.$on("reset_items", this.resetItems);
  this.eventBus.$emit("resend_all_items");
}

// function refreshCustomPrice(newVal, oldVal){
//   const newPrice = parseFloat(newVal)
//   if (isNaN(newPrice)){
//     // Invalid input was given
//
//   }
//   console.log("***CUSTOM", newVal, oldVal)
//   // const item = this.indexedItems[type]
//   // if (isNaN(parseFloat(item.sale_price_dollars))) return
//   // item.sale_price_cents = item.sale_price_dollars * 100
// }

// // Given the type of item, calculates what the given position should be
// function newPosition(type){
//   switch(type){
//       case 'PRODUCT':
//         return _.size(this.productItems)
//       case 'TAX':
//         return _.size(this.indexedItems)
//       case 'DELIVERY':
//         return _.size(this.productItems)+1
//       case 'CUSTOM':
//         return _.size(this.productItems)//+1
//   }
// }

function addItem(id, item) {
  const finalItem = Object.assign({}, item, {
    sale_price_dollars: item.sale_price_cents / 100,
    position: item.position || _.size(this.indexedItems),
    index: id
  });
  this.$set(this.indexedItems, id, finalItem);
  // this.indexedItems.TAX.position = _.size(this.indexedItems)
  this.refreshPositions();
}

function removeItem(id) {
  this.$delete(this.indexedItems, id);
  this.refreshPositions();
}

function resetItems() {
  this.indexedItems = defaultItems();
}

function nonTaxItems() {
  // let itemOrder = (i) => {
  //   switch(i.type){
  //       case 'PRODUCT':
  //         return 1
  //       case 'TAX':
  //         return 4
  //       case 'DELIVERY':
  //         return 3
  //       case 'CUSTOM':
  //         return 2
  //   }
  // } // itemOrder
  return _.chain(this.indexedItems)
    .values()
    .reject((i) => i.type == "TAX")
    .sortBy((i) => i.position)
    .value();
}

function productItems() {
  return _.chain(this.indexedItems)
    .values()
    .filter((i) => i.type == "PRODUCT")
    .value();
}

// Everything but tax & delivery that are dynamic values
function staticItems() {
  return _.reject(_.values(this.indexedItems), (i) => {
    return i.type == "TAX" || i.type == "DELIVERY";
  });
}

function staticItemsTotalCents() {
  return _.reduce(
    this.staticItems,
    (subtotal, item) => {
      return subtotal + item.quantity * (item.sale_price_cents || 0);
    },
    0
  );
}

function deliveryTotalCents() {
  const delivery = this.indexedItems["DELIVERY"];
  if (delivery == undefined) return 0;
  return delivery.sale_price_cents;
}

// If there's delivery, get the static items total and determine the delivery
function calculateDelivery() {
  console.log("Calculating delivery");
  const deliveryItem = this.indexedItems["DELIVERY"];
  if (deliveryItem == undefined) return 0;
  // TODO detect if velidery should be applied
  // 10% of the items
  const applicableDelivery = Math.ceil(this.staticItemsTotalCents * 0.1);
  // 29 USD is the minimum
  const minimumDelivery = DELIVERY_DEFAULT * 100;
  if (applicableDelivery < minimumDelivery) {
    deliveryItem.sale_price_cents = minimumDelivery;
  } else {
    deliveryItem.sale_price_cents = applicableDelivery;
  }
}

function calculateTax() {
  const taxItem = this.indexedItems["TAX"];
  if (taxItem == undefined) return 0;
  taxItem.sale_price_cents = Math.ceil(this.subtotalCents * this.taxRate);
}

function taxValue() {
  const taxItem = this.indexedItems["TAX"];
  if (taxItem == undefined) return 0;
  return taxItem.sale_price_cents;
}

function subtotalCents() {
  return this.staticItemsTotalCents + this.deliveryTotalCents;
}

function totalCents() {
  return _.chain(this.indexedItems)
    .values()
    .reduce((memo, i) => {
      return memo + i.sale_price_cents * i.quantity;
    }, 0)
    .value();
}

function hasVariableQuantity(type) {
  return !(type == "TAX" || type == "DELIVERY");
}

function tipsTotalPrice() {
  return _.chain(this.indexedItems)
  .values().reduce(
    (subtotal, item) => {
      if (item.type === "PRODUCT") {
        subtotal += item.quantity * (item.sale_price_cents || 0);
      }
      return subtotal
    }, 0).value();
}

const customPrice = {
  get: function() {
    return this.onlyMinus
      ? "-"
      : `${this.startWithMinus ? "-" : ""}${
          this.indexedItems.CUSTOM.sale_price_cents === null
            ? ""
            : this.indexedItems.CUSTOM.sale_price_cents / 100
        }${this.endWithDot ? "." : ""}`;
  },
  set: function(newVal) {
    let val = newVal;
    if (!newVal || /^-?[0-9]*(\.?[0-9]{0,2})?$/.test(newVal)) {
      newVal !== "0" &&
        newVal.startsWith("0") &&
        !newVal.startsWith("0.") &&
        (val = newVal.replace(/0/g, ""));
      this.startFromMinus = newVal.startsWith("-");
      this.endWithDot = newVal.endsWith(".");
      this.onlyMinus = newVal === "-";
      this.indexedItems.CUSTOM.sale_price_cents =
        val === "-" || val === "" ? null : Math.round(val * 100);
    }
  }
};

function blurCustom() {
  (this.customPrice === "" || this.customPrice === "-") &&
    (this.customPrice = "0");
}

function buildItemsForSubmit() {
  const clonedItems = _.map(_.values(this.indexedItems), (i) => {
    return Object.assign({}, i);
  });
  const [products, fees] = _.partition(clonedItems, (i) => {
    return i.type == "PRODUCT";
  });
  const finalProducts = _.map(products, (p) => {
    return {
      product_id: p.product_id,
      quantity: p.quantity,
      type: "PRODUCT",
      position: p.position
    };
  });
  const finalFees = _.map(fees, (f) => {
    return {
      name: f.name,
      quantity: f.quantity,
      sale_price_cents: f.sale_price_cents,
      type: f.type,
      position: f.position
    };
  });
  return finalProducts.concat(finalFees);
}

const computed = {
  staticItems,
  staticItemsTotalCents,
  tipsTotalPrice,
  deliveryTotalCents,
  subtotalCents,
  totalCents,
  productItems,
  nonTaxItems,
  customPrice,
  taxValue
};

function refreshPositions() {
  if (this.indexedItems.DELIVERY && this.indexedItems.CUSTOM) {
    this.indexedItems.DELIVERY.position = _.size(this.indexedItems);
  }
  this.indexedItems.TAX.position = _.size(this.indexedItems);

  _.chain(this.indexedItems)
    .pairs()
    .sortBy((p) => {
      p[1].position;
    })
    .each((p, index) => {
      if (p[1].type == "PRODUCT") {
        this.indexedItems[p[0]].position = index;
      }
    });
}

function addTips(tips) {
  this.tips = tips
  this.$emit('tips-update', this.tips)
}

const props = {
  estimateId: { required: true },
  eventBus: { required: true },
  taxRate: { required: true },
  fullOrder: { required: false }
};

const watch = {
  indexedItems: {
    handler: function() {
      this.calculateDelivery();
      this.calculateTax();
    },
    deep: true
  },
  taxRate: function() {
    this.calculateTax();
  },
  totalCents: function(newValue) {
    console.log("SENDING TOTAL", newValue);
    this.$emit("change", newValue);
  }
};

const methods = {
  addItem,
  removeItem,
  resetItems,
  hasVariableQuantity,
  calculateDelivery,
  calculateTax,
  buildItemsForSubmit,
  refreshPositions,
  blurCustom,
  addTips
};

const components = {
  TipBlock
}

export default { data, methods, props, watch, created, computed, components };
</script>
