<template>
  <div class="table-summary">
    <div class="table-summary-content">
      <div class="table-summary-column items-sumarry">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9996 8C15.9996 9.06087 15.5782 10.0783 14.828 10.8284C14.0779 11.5786 13.0605 12 11.9996 12C10.9387 12 9.92131 11.5786 9.17116 10.8284C8.42102 10.0783 7.99959 9.06087 7.99959 8M3.63281 7.40138L2.93281 15.8014C2.78243 17.6059 2.70724 18.5082 3.01227 19.2042C3.28027 19.8157 3.74462 20.3204 4.33177 20.6382C5.00006 21 5.90545 21 7.71623 21H16.283C18.0937 21 18.9991 21 19.6674 20.6382C20.2546 20.3204 20.7189 19.8157 20.9869 19.2042C21.2919 18.5082 21.2167 17.6059 21.0664 15.8014L20.3664 7.40138C20.237 5.84875 20.1723 5.07243 19.8285 4.48486C19.5257 3.96744 19.0748 3.5526 18.5341 3.29385C17.92 3 17.141 3 15.583 3L8.41623 3C6.85821 3 6.07921 3 5.4651 3.29384C4.92433 3.5526 4.47349 3.96744 4.17071 4.48486C3.82689 5.07243 3.76219 5.84875 3.63281 7.40138Z"
            stroke="#23813E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ itemsCount }} Items
      </div>
      <div class="table-summary-column">
        <div class="items-sumarry-description">
          Vendor Total:
        </div>
        <div class="items-sumarry-description">
          Discount ({{ vendorDiscount }}%):
        </div>

        <div class="items-sumarry-description" v-if="vendorPercentageFee > 0">
          Vendor percentage fee ({{ vendorPercentageFee }}%):
        </div>

        <div class="items-sumarry-description" v-if="vendorFee > 0">
          Vendor fee:
        </div>

        <div class="items-sumarry-description amount">
          Amount Due by SimpleCater:
        </div>
        <div class="items-sumarry-description">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_3915_5048)">
              <path
                d="M4.10829 4.10817L15.8916 15.8915M18.3333 9.99984C18.3333 14.6022 14.6023 18.3332 9.99996 18.3332C5.39759 18.3332 1.66663 14.6022 1.66663 9.99984C1.66663 5.39746 5.39759 1.6665 9.99996 1.6665C14.6023 1.6665 18.3333 5.39746 18.3333 9.99984Z"
                stroke="#F79E1B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3915_5048">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          PO price should MATCH and be honored
        </div>
        <div class="items-sumarry-description">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.93 4.93L19.07 19.07M19.0699 4.92999L4.92993 19.07M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#D92D20"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          DO NOT charge Tax
        </div>
      </div>

      <div class="table-summary-column for-values">
        <div class="table-summary-price">
          {{ total }}
        </div>

        <div class="table-summary-price">
          {{ vendorDiscountValue }}
        </div>

        <div class="table-summary-price" v-if="vendorPercentageFee > 0">
          {{ vendorPercentageFeeValue }}
        </div>

        <div class="table-summary-price">
          {{ amount }}
        </div>
      </div>

      <div class="table-summary-column"></div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.table-summary
  font-size: 14px
  background-color: white
  padding: 20px
  &-content
    background: #F0F6F4
    border-radius: 10px
    padding: 20px
    display: flex
    flex-direction: row
    width: 100%
    justify-content: space-between
  // &-column
.items-sumarry
  display: flex
  align-items: center
  gap: 6px
  align-self: flex-start
.items-sumarry-description
    margin-bottom: 8px
    color: #70706B
    display: flex
    align-items: center
    gap: 4px
.amount
    font-weight: 600
.table-summary-price
    margin-bottom: 8px
    font-weight: 600
.for-values
    text-align: right
</style>

<script>
export default {
  props: {
    model: {
      require: true,
    },
    
    vendor: {
      require: true,
    },
  },

  computed: {
    total() {
      return this.$formatCents(
        _.reduce(
          this.model.items,
          (acc, i) => {
            return acc + i.base_price_cents * i.quantity;
          },
          0
        )
      );
    },

    itemsCount() {
      return _.chain(this.model.items)
        .filter((x) => x.type == "PRODUCT")
        .reduce((memo, i) => memo + i.quantity, 0)
        .value();
    },

    vendorDiscount() {
      return this.vendor != null ? this.vendor.global_discount_percentage : 0;
    },

    vendorDiscountValue() {
      const totalInNumber = +this.total.slice(1).replace(/[\s,]/g, "");

      return `$${(Math.floor(((totalInNumber / 100) * this.vendorDiscount) * 100) / 100).toFixed(2)}`;
    },

    vendorPercentageFee() {
      return this.vendor != null ? this.vendor.fee_percentage : 0;
    },

    vendorPercentageFeeValue() {
      const totalInNumber = +this.total.slice(1).replace(/[\s,]/g, "");

      return `$${((totalInNumber / 100) * this.vendorPercentageFee).toFixed(
        2
      )}`;
    },

    vendorFee() {
      return this.vendor != null ? (this.vendor.fee_cents / 100).toFixed(2) : 0;
    },

    vendorFeeValue() {
      const totalInNumber = +this.total.slice(1).replace(/[\s,]/g, "");

      return `$${((totalInNumber * this.vendorFee) / 100).toFixed(2)}`;
    },

    amount() {
      const totalInNumber = +this.total.slice(1).replace(/[\s,]/g, "");
      const discountInNumber = +this.vendorDiscountValue.slice(1);
      const vendorFeeInNumber =
        +this.vendorPercentageFeeValue.slice(1) + +this.vendorFeeValue.slice(1);

      return `$${(totalInNumber - discountInNumber + vendorFeeInNumber).toFixed(
        2
      )}`;
    },
  },
};
</script>
