<template lang='pug'>
el-form(
    :label-width='labelWidth', :label-position='labelPosition'
    :model='address', :rules='rules', ref='addressForm'
  )

  el-form-item(label='Name', prop='Address nickname', v-if="hasName")
    el-input(v-model='address.name', placeholder='Address name')

  el-form-item(label='Address', prop='address_line_1')
    google-autocomplete(
      ref='address', id='map',
      placeholder='Please type your address',
      :enable-geolocation='true',
      v-on:placechanged='onAddressChange', country='us',
      :label-width='labelWidth', :label-position='labelPosition',
      :base-text='address.address_line_1'
    )

  el-form-item(label='Extra info', prop='address_line_2')
    el-input(v-model='address.address_line_2', placeholder='Apartment, suite, etc.')

  el-form-item(label='City', prop='city')
    el-input(v-model='address.city' placeholder='City name')

  el-form-item(label='Zip code', prop='zip_code')
    el-input(v-model='address.zip_code' placeholder='Zip code')
    
  el-form-item(label='State', prop='state')
    el-select(v-model='address.state', placeholder='Select one')
      el-option(v-for='i in states', :value='i.abbr', :label='i.name', :key='i.abbr' :class="forUser ? 'user-select' : ''")

  el-form-item(v-if='hasNotes', label='Notes', prop='notes')
    el-input(type="textarea", v-model="address.notes", :autosize="{ minRows: 10, maxRows: 30}")
    br

  el-form-item(v-if='displaySave')
    el-button(
      type="primary",
      @click="onSave", :loading='loading'
    ) Save
</template>

<script>
  // import {states} from 'us'
  import GoogleAutocomplete from './GoogleAutocomplete.vue'
  import Vue from 'vue'
  import settings from './settings.js'
  import _ from 'underscore'

  function data(){
    return {
      address: Object.assign({},this.baseData),
      zipCodes: '',
      states: settings.states,
      rules: {
        address_line_1: {
          required: true, message: 'Please input the address',
          trigger: 'blur'
        },
        //address_line_2: '',
        state: [
          {
            required: true, message: 'Please select a state'
          },
          {
            type: "enum", enum: _.map(settings.states, (s)=>s.abbr),
            message: "State is out of the coverage area"
          }
        ],
        zip_code: [
          {
            required: true, message: 'Please input a valid zip code',
            pattern: /\d+/, trigger: 'blur'
          },
          {
            len: 5, trigger: 'blur',
            message: 'Please input a 5 digit zip code'
          }
        ],
        city: {
          required: true, message: 'Please enter a city'
        }
      }
    }
  }

  function onAddressChange(addressData){
    let line_1_parts = []

    if(addressData.street_number != undefined){
      line_1_parts.push(addressData.street_number)
    }

    line_1_parts.push(addressData.route)

    const line_1 = line_1_parts.join(" ")

    this.address.address_line_1 = line_1
    this.address.city = addressData.locality
    this.address.state = addressData.administrative_area_level_1
    this.address.zip_code = addressData.postal_code
    this.address = Object.assign({}, this.address)
    Vue.nextTick(()=>{
      this.$refs.address.update(line_1)
    })
  }

  function validate(callback){
    this.$refs.addressForm.validate((valid)=>{
      if (valid){
        callback()
      }else{
        callback(new Error('Please verify the address form'))
      }
    })
  }

  function buildPayload(){
    if(this.newRequest){
      return Object.assign({},this.address,{
        zip_code: parseInt(this.address.zip_code)
      })
    }
    else{
      return Object.assign({},this.address,{
        customer_id: this.customerId,
        zip_code: parseInt(this.address.zip_code)
      })
    }

  }

  function create(){
    return new Promise((resolve, reject)=>{
      this.$refs.addressForm.validate((valid)=>{
        if (valid){
          const payload = this.buildPayload();
          const apiUrl = this.newRequest ? `/customers/${this.customerId}/addresses` : '/addresses';

          this.$simplecater.post(apiUrl, payload).then((r)=>{
            // TODO handle errors, redirect on unathorized
            resolve(r.data.data)
          })
          // TODO catch
        }else{
          this.$message.error('The address cannot be created because it contains errors')
          reject('The address cannot be created because it contains errors')
        }
      })
    })
  }

  function onSave(){
    this.$refs.addressForm.validate((valid)=>{
      if (valid){
        this.$emit('saveClick', this.buildPayload())
      } else {
        this.$message.error('The address cannot be created because it contains errors')
      }
    })
  }

  const props = {
    labelWidth: { default: Vue.$labelWidth },
    labelPosition: { default: Vue.$defaultlabelPosition },
    displaySave: {default: false},
    loading: {default: false},
    customerId: {required: false},
    newRequest: {required: false},
    hasNotes: { type: Boolean, default: false, },
    hasName: { type: Boolean, default: false, },
    forUser: { type: Boolean, default: false, },
    baseData: {
      default: function(){
        return {
          name: '',
          address_line_1: '',
          address_line_2: '',
          state: null,
          zip_code: '',
          city: ''
        }
      }
    }
  }

  let watch = {
    baseData: function ( newVal, oldVal ) {
      if ( !oldVal && !!newVal ) {
        // this.$refs.address.update(this.baseData.address_line_1)
        this.address = Object.assign({}, newVal, {
          zip_code: newVal.zip_code.toString(),
        })
      }
    },
  }

  const components = { GoogleAutocomplete }

  const methods = { validate, create, onAddressChange, onSave, buildPayload }

  export default { data, methods, components, props, watch }
</script>
