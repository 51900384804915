<style scoped lang="sass">

.section-separator
  margin-bottom: 1.5rem
  .the-divider
    display: flex
    flex-direction: rows
    margin: 0.5rem 0 0.5rem
    & > div
      display: inline-block
      line-height: 2.5rem
    .line
      flex: 1
      position: relative
      &:before
        content: ''
        position: absolute
        top: 50%
        left: 0
        width: 100%
        height: 1px
        background: #ddd
    .title
      font-size: 1rem
      // text-transform: uppercase
      padding: 0 0.5em
      // color: #444
      color: $colorMenu
  .the-bottom-divider
    position: relative
    &:before
      content: ''
      position: absolute
      top: 50%
      $w: 4rem
      width: $w
      height: 1px
      background: rgba(black, 0.1)
      left: calc(50% - calc($w / 2))
</style>

<template lang="pug">
  .section-separator
    .the-divider
      .line
      .title {{ label }}
      .line
    slot
    .the-bottom-divider &nbsp;
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
