<template>
  <div class="main-container">
    <div class="view-content">
      <h2 class="page-title">Requests</h2>
      <div class="sub-header">
        <div class="sub-header-left-part">
          <toggle-switch
            :options="toggleOption"
            :disabled="false"
            @change="switchTab($event.value)"
          />
            <el-select  placeholder='Status' v-model="curentFilterStatus">
              <el-option class="user-select" v-for="status in (activeIndex === 'open' ? filterStatusesPending : filterStatusesComplete)" :value="status.value" :label="status.label" :id="status.id" :key="status.id" />
            </el-select>
        </div>
        <el-input class="search" v-model="sorting" prefix-icon="el-icon-search" placeholder="Search requests..."></el-input>
      </div>

      <div v-for="filter in filters" :label="$filterToStaffName(filter)" :name="filter" :key="filter">
        <request-list :filter="filter" :sorting="sorting" v-if="filter == activeIndex" :curentFilterStatus="curentFilterStatus"></request-list>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import RequestList from './blocks/RequestList.vue'
import ToggleButton from './blocks/ToggleButton.vue'

function data(){
  return {
    filters: ['open', 'closed'],
    sorting:'',
    filterStatusesPending: [
      {id: 1, label: 'All Statuses', value: 'ALL_STATUSES'}, 
      {id: 2, label: 'Waiting for Estimates', value: 'WAITING_FOR_ESTIMATES'}, 
      {id: 3, label: 'Estimates Available', value: 'ESTIMATES_AVAILABLE'}, 
    ],
    filterStatusesComplete: [
      {id: 1, label: 'All Statuses', value: 'ALL_STATUSES'}, 
      {id: 2, label: 'Cancelled', value: 'CANCELLED'}, 
      {id: 3, label: 'Estimate Ordered', value: 'ESTIMATE_ORDERED'}, 
      {id: 4, label: 'Closed', value: 'CLOSED'}
    ],
    curentFilterStatus: 'ALL_STATUSES',
    activeIndex: 'open',
    toggleOption: {
      layout: {
        color: 'black',
        backgroundColor: 'white',
        selectedColor: 'white',
        selectedBackgroundColor: '#125F43',
        borderColor: 'black',
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: true
      },
    size: {
        fontSize: .875,
        height: 2.125,
        padding: .4375,
        width: 11.25
    },
    config: {
        delay: 0,
        preSelected: 'open',
        disabled: false,
        items: [
            { name: 'Open', value: 'open', color: '#125F43', backgroundColor: '#E9F2EC' },
            { name: 'Closed', value: 'closed', color: '#125F43', backgroundColor: '#E9F2EC' }
        ]
      }
   }
  }
}

function goToRequestBuilder ( ) {
  this.$router.push("/user/requests/new")
}

function switchTab (e) {
  this.curentFilterStatus = 'ALL_STATUSES'
  this.activeIndex = e
}

const components = { RequestList, ToggleButton }

const methods = { goToRequestBuilder, switchTab }

export default { methods, components, data }
</script>

<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
  .search
    width: 12.5rem
  .sub-header-left-part
    display: flex
    gap: .75rem  
</style>