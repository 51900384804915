<template lang="pug">
div#user-rewards-activities
  el-table(:data='activities')
    el-table-column(prop='date', label='Date')
    <el-table-column label="Activity">
      <template slot-scope="scope">
        <span v-html="scope.row.activity"></span>
      </template>
    </el-table-column>
    el-table-column(prop='amount', label='Amount')
    el-table-column(prop='balance', label='Balance')

  el-col
    el-pagination( 
      v-if="pagination.pages > 1"
      @current-change="onPaginationChange"
      :page-size="pagination.per_page", 
      :pager-count="5", 
      layout="prev, pager, next", 
      :total="pagination.total")
</template>

<script>
export default {
  name: 'UserRewardsActivities',

  data() {
    return {
      activities: null,
      pagination: {
        per_page: null,
        current: 1,
        pages: null,
        total: null,
      }
    }
  },

  methods: {
    async getPointsActivities(current_page) {
      let params = current_page ? { params: { page: current_page } } : {};

      await this.$simplecater.get(`/points/activities`, params).then((r) => {
        const { data, pagination } = r.data;
        this.activities = data.map((activity) => {
          activity.date = activity.date.split(' ')[0];

          if(activity.activity_type == 'redeemed_points') {
            let link = activity.activity;
            activity.activity = `Click to <b><a href="${link}" target="_blank">this link</a></b> to review your gift card`;
          }

          return activity;
        });

        this.pagination = pagination;
      }).catch(() => {
        this.$message.error("Failed to load the customer point activities");
      });
    },

    onPaginationChange(current_page) {
      this.getPointsActivities(current_page);
    }
  },

  created() {
    this.getPointsActivities(this.pagination.current);
  },
}
</script>

<style lang="sass">
#user-rewards-activities
  .el-table__header-wrapper
    border-radius: 8px 0px 0px 8px
    
  .el-table th.el-table__cell
    background-color: #FAFAF5
    border-bottom: none

  .el-table th.el-table__cell > .cell
    display: flex
    flex-direction: row
    align-items: center
    font-family: Inter
    font-size: 12px
    font-weight: 500
    line-height: 18px
    letter-spacing: -0.03em
    text-align: left
    color: #70706B
    

  .el-table td.el-table__cell > .cell
    display: flex
    flex-direction: row
    align-items: center
    font-family: Inter
    font-size: 14px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.03em
    text-align: left
    color: #4A4A45


</style>