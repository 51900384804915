<template lang='pug'>
div(ref='table')
  div(v-if='estimate != null')
    el-row(:gutter='$rowGutter')
      el-col(:span='12')
        .products-from
          |  Products from: <strong>{{estimate.vendor_name}}</strong>&nbsp;&nbsp;
          el-tag(type='info') {{estimate.cuisine_name}}
          br
          small Price per person: <strong>{{ $formatCents(pricePerPerson) }}</strong>

      el-col(class='print-version' :span='12')
        el-button(v-if='remoteId' type='text', @click='$openInBlank(`/print/estimate/${remoteId}`)') Printable version

    el-row(:gutter='$rowGutter')
      el-col(:span='24')
        el-table(:data='itemsWithoutTax')
          el-table-column(label='Item', :width='width')
            template(slot-scope='scope')
              div {{scope.row.name}}
              .secondary(v-if='scope.row.type == "PRODUCT"') {{scope.row.description}}
              .secondary(v-if='scope.row.serves') serves {{scope.row.serves}}
          el-table-column(prop='quantity', label='Quantity', width='160')
          el-table-column(label='Unit Price', width='160')
            template(slot-scope='scope')
              | {{$formatCents(scope.row.sale_price_cents) }}
          el-table-column(label='Total Price', width='160')
            template(slot-scope='scope')
              | {{$formatCents(scope.row.sale_price_cents*scope.row.quantity) }}

        el-row(:gutter='$rowGutter', v-if='totalCents && subtotalCents')
          el-col(:span='12') &nbsp;
          el-col(:span='9', style='text-align: right;')
            .numeric-summary
              .label Subtotal
              .value {{ $formatCents(subtotalCents) }}
            .numeric-summary
              .label Tax
              .value {{ $formatCents(tax.sale_price_cents) }}
            .numeric-summary
              .label
                big Total
              .value
                big {{ $formatCents(totalCents) }}

    br

    el-row(:gutter='$rowGutter', style='margin-top: 1rem', v-if="estimate.status != 'REJECTED'")
      el-col(:span='24', style='text-align: right')
        el-button(type='green', @click='onAccept', :loading='submitting') Submit
        el-button(type='primary', @click='onRequestChanges', :loading='submitting') Request changes
        el-button(type='primary', @click='onReject', :loading='submitting') Reject

    accept-estimate-dialog(
      :estimate='estimate'
      :request='this.request',
      :address='this.address',
      :visible.sync='displayAcceptDialog'
    )
</template>

<script>
import _ from 'underscore'
import AcceptEstimateDialog from './UserAcceptEstimateDialog.vue'
import StatusTag from '../../common/StatusTag.vue'

function data(){
  return {
    estimateId: this.remoteId,
    displayAcceptDialog: false,
    submitting: false,
    estimate: null,
    vendors: null,
    productSelection: null
  }
}

function mounted(){
  this.loadFullEstimate()
}

function loadFullEstimate(){
  const fullEstimate = `/estimates/${this.estimateId}`
  return this.$simplecater.get(fullEstimate).then((r)=>{
    if (this.estimateId == r.data.data.id) {
      const estimateData = r.data.data
      this.estimate = estimateData
    }
  }).catch(()=>{
    this.$message.error("Failed to load the list of products")
  })
}

function onAccept(){  
  this.displayAcceptDialog = true
}

function onReject(){
  this.$prompt('What didn’t you like about this menu?', 'Reject', {
    confirmButtonText: 'Reject submit',
    cancelButtonText: 'Cancel'
  }).then((result)=>{
    const target = `/estimates/${this.estimate.id}/reject`
    this.$simplecater.post(target,{customer_notes: result.value}).then((r)=>{
      this.estimate = r.data.data
      this.$message("The estimate has been rejected")
    }).catch(()=>{
      this.$message.error("Rejection failed")
    })
  })
  
}

function onRequestChanges(){
  this.$prompt('What would you like to be changed?', 'Request changes', {
    confirmButtonText: 'Request changes',
    cancelButtonText: 'Cancel'
  }).then((result)=>{
    console.log("CHANGES",result.value)
    const target = `/estimates/${this.estimate.id}/request_changes`
    this.$simplecater.post(target, {customer_notes: result.value}).then(()=>{
      this.$message("The have changes have been requested")
      this.$router.push('/user/requests')
    }).catch(()=>{
      this.$message.error("The request for changes failed")
    })
  })

}

const computed = {

  itemsWithoutTax ( ) {
    return ( this.estimate )? _.chain(this.estimate.items).filter(( x ) => (x.type != "TAX")).sortBy(( x ) => (x.position) ).value() : null
  },

  tax ( ) {
    return ( this.estimate )? _.find(this.estimate.items, ( x ) => (x.type === "TAX")) : null
  },

  subtotalCents ( ) {
    return _.reduce(this.estimate.items, (memo, i) => {
      return ( i.type != "TAX" )? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0)
  },

  subtotalCentsWithoutDelivery ( ) {
    return _.reduce(this.estimate.items, (memo, i) => {
      return ( i.type != "TAX" && i.type != "DELIVERY" )? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0)
  },

  subtotalCentsProductsOnly() {
    return _.reduce(this.estimate.items, (memo, i) => {
      return ( i.type != "TAX" && i.type != "DELIVERY" && i.type != "CUSTOM")? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0)
  },

  totalCents () {
    return _.reduce(this.estimate.items, (memo, i) => {
      return memo + (i.sale_price_cents * i.quantity)
    }, 0)
  },

  tipsTotalPrice() {
    return _.reduce(this.estimate.items, (memo, i) => {
      if (i.type === "PRODUCT") {
        memo += (i.sale_price_cents * i.quantity)
      }
      return memo
    }, 0)
  },

  pricePerPerson () {
    return this.subtotalCentsWithoutDelivery / this.people
  },

  width() {
    return this.$refs && this.$refs.table && this.$refs.table.clientWidth ? this.$refs.table.clientWidth - 480  : '';
  }

}


const props = {
  remoteId: { required: false },
  requestId: { required: true },
  index: { required:  true },
  people: { },
  request: { required: true },
  address: { required: true },
}

const components = {
  AcceptEstimateDialog,
  StatusTag,
}

const methods = {
  loadFullEstimate,
  onReject, onAccept, onRequestChanges
}

export default { data, methods, props, components, computed, mounted }
</script>

<style scoped lang="sass">
  
  .numeric-summary
    width: 100%
    display: flex
    & > div
      flex: 0 0 50%
  .status-wrapper
    margin: 0 0 0.5rem
  .secondary
    font-size: 12px
    font-style: italic
    white-space: pre-line
    word-break: break-word

  .print-version
    text-align: right
</style>
