<template>
  <drawer :dismissRoute="dismissRoute" :small="false">
      <div class="drawer-title">{{title || "New Address"}}</div>
      <div class="drawer-description">Complete address would assist better us in serving you!</div>
      <address-builder :displaySave="true" @saveClick="createAddress" :loading="loading" has-name="has-name" :baseData="defaultValues"></address-builder>
  </drawer>
</template>


<script>
  import Drawer from '../layout/Drawer.vue'
  import AddressBuilder from '../common/UserAddressBuilder.vue'

  const editData = {
    // TODO change endpoint when backend will be ready
    target: "/addresses",
    messageError: "Failed to edit the address",
    messageSuccess: "The address has been edited",
  }

  const createData = {
    target: "/addresses",
    messageError: "Failed to create the address",
    messageSuccess: "The address has been created"
  }

  function createAddress(address){
    this.loading = true
    const id = this.$route.params.id;
    this.$simplecater[this.method](`${this.target}${id && `/${id}` || ''}`, address).then(()=>{
      this.$message(this.messageSuccess)
      this.loading = false
      this.$bus.$emit('address-saved')
      this.$router.push(this.dismissRoute)
    }).catch(()=>{
      this.$message.error(this.messageError)
      this.loading = false
    })
  }

  function data ( ) {
    const isEdit = this.$route.params.id;
    return {
      dismissRoute: "/user/address_book",
      loading: false,
      defaultValues: null,
      title: null,
      method: isEdit ? 'put' : 'post',
      ...(isEdit ? editData : createData)
    }
  }

  function created ( ) {
    if(this.$route.params.id) {
      this.loading = true;
      this.$simplecater.get(`/addresses/${this.$route.params.id}`).then(({ data: { data: {
        address_line_1,
        address_line_2,
        city,
        zip_code,
        state,
        name
      } }}) => {
        this.title = address_line_1 ? 'Edit Address' : null
        this.defaultValues = {
          name,
          address_line_1: address_line_1 || '',
          address_line_2: address_line_2 || '',
          city,
          zip_code,
          state
        }
      })
      .catch(() => this.$router.push(this.dismissRoute))
      .finally(() => (this.loading = false))
    }
  }

  const components = { Drawer, AddressBuilder }

  const methods = { createAddress }

  export default { data, created, components, methods }
</script>

<style scoped lang="sass">
.drawer-title
  color: black
  font-size: 1.2rem
  font-weight: 500
  border: none
  margin-bottom: 0
.drawer-description
  color: #70706B
  font-size:  .75rem
  margin-bottom: 1.5rem  
</style>