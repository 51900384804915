import { render, staticRenderFns } from "./AddressesInsert.vue?vue&type=template&id=74a22e6e&scoped=true&"
import script from "./AddressesInsert.vue?vue&type=script&lang=js&"
export * from "./AddressesInsert.vue?vue&type=script&lang=js&"
import style0 from "./AddressesInsert.vue?vue&type=style&index=0&id=74a22e6e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a22e6e",
  null
  
)

export default component.exports