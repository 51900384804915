<template lang='pug'>
header(:class="{ 'no-padding': noPadding === true, 'no-border': noBorder, 'no-margin': noMargin }")
  .seller
    .brand
      //- img(src='http://docraptor.com/samples/docraptor-logo.svg', width='60', height='43')
      img(src='../assets/logo-new-print.png', height='60')
      
  //- .status.paid PAID
  .buyer
    slot
  .clearfix
</template>


<style lang="sass">

header
  margin: 0 15mm 5mm
  border-bottom: 1px solid #7E7E7E
  padding-bottom: 5mm
  &.no-padding
    padding: 0

.seller, .status
  float: left

.buyer
  float: right
  text-align: right

.seller
  width: 50%

.buyer
  max-width: 49%

.brand
  font-weight: bold
  font-size: 30px
  color: #333
  padding: 0
  overflow: auto
  margin-top: 25pt

.brand span, .brand img
  float: left

.brand span
  display: block
  margin: 0 0 0 5px

.seller address
  clear: both
  display: block
  padding-left: 65px
  ont-size: 10pt
  line-height: 12pt
  color: #262626

.seller address span
  display: block

.status.paid
  margin: 25pt 0 0 15pt
  letter-spacing: -1pt
  font-weight: bold
  color: #78E400
  font-size: 30pt
  border: 5px solid #78E400
  padding: 1pt 5pt
  transform: rotate(-15deg)
  -ms-transform: rotate(-15deg) /* IE 9 */
  -webkit-transform: rotate(-15deg) /* Safari and Chrome */

.buyer
  font-size: 9pt
  line-height: 12pt
  margin-top: 25pt
  color: #262626

.buyer h2
  font-weight: bold
  font-size: 11pt

.buyer span
  display: block


.clearfix
  clear: both

.no-border
  border: none

.no-margin
  margin-bottom: 0  
</style>

<script>
export default {
  components: { },
  props: {
    noPadding: {
      default: false,
      type: Boolean,
    },
    noBorder: {
      default: false,
      type: Boolean,
    },
    noMargin: {
      default: false
    }
  },
}
</script>
