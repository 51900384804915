import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'nprogress/nprogress.css'
import './_element-variables.sass'
import locale from 'element-ui/lib/locale/lang/en'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router.js'
import NProgress from 'vue-nprogress'
import auth from './auth.js'
// import VueStripeCheckout from 'vue-stripe-checkout'

import Toolbar from './layout/Toolbar.vue'
import SectionSeparator from './layout/SectionSeparator.vue'
import Drawer from './layout/Drawer'
import _ from 'underscore'
import './style.sass'
import moment from 'moment'
import ToggleSwitch from 'vuejs-toggle-switch'

const progressOptions = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
}

Vue.use(VueRouter)
Vue.use(ElementUI, { locale })
Vue.use(NProgress, progressOptions)
Vue.use(ToggleSwitch)

// Stripe Pptions
// These options can be overridden by the options in the .open(options) function.
// https://stripe.com/docs/checkout#integration-simple-options
// let birdLogo = require('./assets/bird-logo-256.png')
// const options = {
//   key: process.env.VUE_APP_STRIPE_KEY,
//   image: birdLogo,
//   // locale: 'auto',
//   currency: 'USD',
//   billingAddress: true,
//   panelLabel: 'Subscribe {{amount}}'
// }

// Vue.use(VueStripeCheckout, options)

// Global components
Vue.component('toolbar', Toolbar)
Vue.component('section-separator', SectionSeparator)
Vue.component('drawer', Drawer)

Vue.prototype.$labelWidth = '11rem'
Vue.prototype.$rowGutter = 20
Vue.prototype.$defaultFormSize = 'medium'
Vue.prototype.$defaultlabelPosition = 'right'

Vue.prototype.$upcaseFirst = function(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

Vue.prototype.$openInBlank = function(url){
  const routeData = this.$router.resolve(url)
  window.open(routeData.href, '_blank')
}

Vue.prototype.$formatCents = function(cents){
  return ("$" + (cents/100).toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2}))
}

Vue.prototype.$formatDate = function ( input ) {
  const momentDate = moment(input, "YYYY-MM-DD")
  let format = "ddd, MMM Do"
  const currentYear = moment().year()
  // Only display the year if it's different from the current one
  if ( momentDate.year() != currentYear ) format = format + " YYYY"
  return momentDate.format(format)
}

Vue.prototype.$formatTime = function ( input ) {
  let ret = 'N/A'
  if ( input ) {
    const momentDate = moment(input, "HH:mm A")
    ret = momentDate.format("hh:mm A")
  }
  return ret
}

Vue.prototype.$filterToStaffName = function ( x ) {
  if ( x === 'open' ) {
    return 'Pending'
  } else if ( x === 'closed' ) {
    return 'Complete'
  } else {
    return '--'
  }
}

Vue.prototype.$mergeWithTips = function ( tip, data) {
  if(!tip) return data
  data.items.push({
    name: "Driver tip",
    total_cents: tip,
    sale_price_cents: tip,
    type: "TIP",
    quantity: 1
  })
  return data
}

Vue.prototype.$filterToCustomerName = function ( x ) {
  if ( x === 'open' ) {
    return 'Open'
  } else if ( x === 'closed' ) {
    return 'Completed'
  } else {
    return '--'
  }
}

Vue.prototype.$handleErrorFor = function(activity){
  return function(error){
    console.log("***" + activity + " failed", error)
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      switch(error.response.status){
        case 422: {
          const msg = "Invalid data was sent to the server. "
          const respError = error.response.data.error
          if(respError.messages){
            const fields = _.keys(error.response.data.error.messages).join(", ")
            this.$message.error(msg + "Please verify: " + fields)
          }else{
            this.$message.error(msg + respError.reason)
          }
          break
        }
        case 403:
        case 401:
          this.$message.error("Your session doesn't allow you to perform this operation")
          break
        case 500:
          this.$message.error("The server is currently unavailable, please try again in a moment")
          break
      }
    } else if (error.request) {
      this.$message.error("Unable to reach server")

      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      this.$message.error("Unexpected error preparing the request")
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
  }
}

Vue.prototype.$isNumber = function (n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

Vue.prototype.$bus = new Vue()

const nprogress = new NProgress({ parent: '.nprogress-container' })

auth.setupSimpleCaterClient()

const app = new Vue({
  router,
  nprogress,
  el: '#app',
  render: h => h(App)
})

const watchSession = () => {
  if(!auth.anyValidSession()){
    auth.logout()
    app.$router.push('/')
  }
}

setInterval(watchSession, 1000)
