<template lang="pug">
  .main-container
    .view-content
      h2.page-title Rewards

      el-card
        .user-points-dashboard
          .user-points
            h2 {{ formatNumber(points) }}
            | Points

          .user-money
            h2 {{ formatPointsIntoMoney(points) }}
            | {{ exchangeRate }} points = $1 of rewards

      el-card.use-points-section
        .use-points-options
          h2 How would you like to use your points?
          .options
            .option
                div.amazon-logo
                  img(src="../assets/amazon-logo-new.png" alt="Get a gift card", width="220", style="padding-top: 35px;")
                <el-button type="primary" @click="showGiftCardModal('amazon-card')">Get a gift card</el-button>
            .option
              div
                img(src="../assets/rewards-towards-catering.svg" alt="Towards a catering", width="150", height="150")
              <router-link to="/user/requests/new">
                <el-button type="primary">Towards a catering</el-button>
              </router-link>
              
      el-card.gift-cards-form
       el-form(:model='giftCardForm' class='gift-cards' :rules='rules' ref='giftCardForm' label-position='top')
          el-form-item(
            label='Enter the dollar value of your gift card ($10 minimum):' 
            class='row' 
            style="margin-bottom: 12px; display: block;" 
            prop="amount"
          )
            el-input(
              v-model='giftCardForm.amount'
              placeholder='0'
              style='width: 25%; min-width: 208px;'
              id='gift-card-amount'
            )
              template(slot="prepend") $
          
          .options
            .info
              .option
                h2 Points Needed
                .value {{ convertDollarsIntoPoints(giftCardForm.amount) }}
              .option
                h2 Gift Card Type
                .value {{ giftCardForm.provider.toUpperCase() }}
              .option
                h2 Remaining Points
                .value {{ formatNumber(points - (convertDollarsIntoPoints(giftCardForm.amount) || 0)) }}

          el-row.buttons-section
            el-col(:span='8', :offset='16', style='text-align: right')
              el-button.points-button(type='primary', @click='redeemPoints' :loading='submitting') Submit


      el-row(:gutter="20")
        el-col(:span="12")
          h3.section-title Earn Points
          el-card.rewards-list
            ul
              li Earn at least 1 point for every $1 spent on food products from participating vendors.
              li Points will be credited to your account once the order payment has been processed.
              li Points expire 1 year after your last order.
              li SimpleCater may adjust points for unusual circumstances.
              li Points are non-transferable.
        el-col(:span="12")
          h3.section-title Spend Points
          el-card.rewards-list
            ul
              li Use points as credit for caterings from eligible vendors.
              li Estimates will display your "max eligible" points, indicating how many you can redeem.
              li If your order is reduced, the points used will adjust to the new "max eligible" amount.
              li Points can also be used for digital gift cards, subject to a minimum redemption amount.
              li Points are not redeemable for cash or checks

      h3.section-title Points Activity
      el-card
        user-rewards-activities(:key='refreshActivities')
  
    <pop-up :showPopUp="successDialivVisible" width='608px' height='376px' @closePopUp="successDialivVisible = false" id='redeem-popup'>
      div#redeem-success
        img(src="../assets/amazon-card.png" alt="Success")
        h2 Your request has been approved
        p <a :href="link" target="_blank" @click.prevent="handleLinkClick">Click <u>HERE</u> to review your gift card</a>
    </pop-up>
</template>

<script>
import UserRewardsActivities from './UserRewardsActivities.vue';
import PopUp from '@/common/PopUp.vue'

export default {
  name: 'UserRewards',

  data() {
    return {
      account: null,
      points: 0,
      exchangeRate: 0,
      selectedCard: null,
      giftCardForm: {
        amount: null,
        provider: 'amazon'
      },
      rules: {
        amount: [
          {
            validator: (_rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }
              const pattern = /^\d+$/;
              if (!pattern.test(value)) {
                callback(new Error('Should be a number'));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          },
          {
            validator: (_rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }

              if (value < 10) {
                callback(new Error('The amount must be not less than 10'));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          },
          {
            validator: (_rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }

              const pointsNeeded = this.exchangeRate * value;
              if ((this.points - pointsNeeded) < 0) {
                callback(new Error('Not enough points'));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ]
      },
      submitting: false,
      refreshActivities: 0,
      successDialivVisible: false,
      link: ''
    }
  },

  methods: {
    getAccount() {
      this.$simplecater.get('/account').then((r) => {
        this.account = r.data.data

        if (!this.account.rewards) { this.$router.push('/user/overview') }
      }).catch(() => {
        this.$message.error("Failed to load the account information")
        this.$router.push('/')
      })
    },

    getPoints() {
      this.$simplecater.get(`points/`).then((r) => {
        this.points = r.data.data.amount;
      }).catch(() => {
        this.$message.error("Failed to load the customer's points");
      })
    },

    getSettings() {
      this.$simplecater.get(`/settings`).then((r) => {
        let settings = r.data.data.find((setting) => setting.key === 'POINTS_EXCHANGE_RATE');

        if (settings) {
          this.exchangeRate = settings.value;
        }
      }).catch(() => {
        this.$message.error("Failed to load the settings");
      })
    },

    formatPointsIntoMoney(points) {
      const fixedDollars = (points / this.exchangeRate).toFixed(2);

      return `$${fixedDollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },

    formatPointsIntoMoneyRounded(points) {
      const fixedDollars = parseInt(points / this.exchangeRate).toFixed(2);

      return `$${fixedDollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    },

    convertDollarsIntoPoints(dollars) {
      let amount = 0;
      const giftForm = this.$refs.giftCardForm;
      if (!giftForm) { return 0; }

      giftForm.validate((valid) => {
        if (!valid) {
          amount = 0;
        } else {
          amount = this.exchangeRate * dollars;
        }
      })

      return amount;
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    
    showGiftCardModal(selectedOption) {
      this.selectedCard = selectedOption;
      const redeemForm = document.querySelector(".gift-cards-form");
      redeemForm.style.display = "block";
      
      const usePointsSection = document.querySelector(".use-points-section");
      usePointsSection.style.display = "none";

      if (selectedOption === 'visa-card') {
        this.giftCardForm.provider = 'visa';
      } else if (selectedOption === 'amazon-card') {
        this.giftCardForm.provider = 'amazon';
      }
    },
    
    makeOptionActive(refName) {
      const cardOptions = document.querySelectorAll(".gift-cards-section .option");
      if (cardOptions) {
        for (let i = 0; i < cardOptions.length; i++) {
          cardOptions[i].classList.remove("selected");
        }
      }

      const element = this.$refs[refName];
      
      if (element) {
        element.classList.add('selected');
      }
    },

    redeemPoints() {
      this.submitting = true;

      this.$refs.giftCardForm.validate((valid) => {
        if(!!this.giftCardForm.amount == false) {
            valid = false;
        }

        if (valid) {
          let payload = {
            amount: parseInt(this.giftCardForm.amount) * 100,
            provider: this.giftCardForm.provider
          }

          this.$simplecater.post('/points/redeem-points', payload).then((responce) => {
            this.submitting = false;
            this.points = responce.data.data.amount;
            this.link = responce.data.data.link;
            this.refreshActivities++;
            this.successDialivVisible = true;
          }).catch(() => {
            this.submitting = false;
            this.$message.error("Failed to update the points");
          })

          this.resetForm();
        } else {
          this.submitting = false;
          return false;
        }
      })
    },

    resetForm() {
      const redeemForm = document.querySelector(".gift-cards-form");
      redeemForm.style.display = "none";
      
      this.$refs.giftCardForm.resetFields();

      const usePointsSection = document.querySelector(".use-points-section");
      usePointsSection.style.display = "block";
    },

    handleLinkClick() {
      this.successDialivVisible = false;
      window.open(this.link, '_blank');
    }
  },

  created() {
    this.getAccount();
    this.getPoints();
    this.getSettings();
  },

  components: {
    UserRewardsActivities, PopUp
  }
}
</script>

<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
    color: #1F1F18

  .section-title
    font-family: Inter
    font-size: 16px
    font-weight: 500
    line-height: 24px
    letter-spacing: -0.03em
    text-align: left
    color: #A2A29D
    margin: 40px 0 20px 0

  .user-points-dashboard
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
    gap: .9375rem
    font-size: 16px
    line-height: 24px
    letter-spacing: -0.03em
    text-align: center
    color: #6E6E68

    h2
      font-size: 2.5rem
      font-family: Inter
      font-size: 36px
      font-weight: 500
      line-height: 36px
      letter-spacing: -0.05em
      color: #1F1F18
      margin: 0 0 0.5rem 0

    .user-points
      width: 100%
      padding: 1.5rem
      background: #F1F1E3
      border-radius: 12px

    .user-money
      width: 100%
      padding: 1.5rem
      background-color: #FAFAF5
      border-radius: 12px

  .use-points-options, .gift-cards
    h2
      font-family: Inter
      font-size: 20px
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.03em
      text-align: center
      color: #1F1F18
      margin: 0

  .options
    display: flex
    flex-direction: row
    justify-content: space-between
    gap: 1.25rem
    margin-top: 1.25rem

    .option
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      padding: 1.5rem
      background: #FAFAF5
      border-radius: 12px
      text-align: center

      .el-button
        padding: 12px 26px
        gap: 8px
        max-width: 178px
        background: #125F43
        border-color: #125F43
        border-radius: 12px
        margin-top: 14px
        text-align: center
    
    .inavtive
      color: #6E6E68
      cursor: not-allowed

  .gift-cards-section
    display: none

    .option.selected
      border: solid 1px #125f43
      background: #E9F2EC
  
  .gift-cards-form
    display: none

    .gift-cards
      label
        color: #1F1F18 !important
    
    .info
      width: 100%
      display: flex
      gap: 0.9375rem

      h2
        width: 100%
        font-family: Inter
        font-size: 20px
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.03em
        text-align: left
        padding-bottom: 20px
        border-bottom: 1px solid #EBEBE4
      
      .value
        padding-top: 20px
        font-family: Inter
        font-size: 20px
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.03em
        text-align: left
      
      .option
        justify-content: left
        align-items: start


    .el-button
      padding: 12px 26px
      gap: 8px
      max-width: 178px
      background: #125F43
      border-color: #125F43
      border-radius: 12px
      margin-top: 14px
      text-align: center

  .rewards-list
    ul li
      font-family: Inter
      font-size: 14px
      font-weight: 500
      line-height: 20px
      letter-spacing: -0.03em
      text-align: left
</style>

<style lang="sass">
  #redeem-popup
    .title-section
      margin-bottom: 0 !important

  #redeem-success
    display: flex
    flex-direction: column
    text-align: center
    align-items: center
    justify-content: center

    h2
      font-family: Inter
      font-size: 24px
      font-weight: 500
      line-height: 24px
      letter-spacing: -0.04em
      text-align: center
      text-underline-position: from-font
      text-decoration-skip-ink: none
      color: #1F1F18
      margin-bottom: 12px

    p
      font-family: Inter
      font-size: 16px
      font-weight: 500
      line-height: 24px
      letter-spacing: -0.03em
      text-align: left
      text-underline-position: from-font
      text-decoration-skip-ink: none
      color: #70706B
      margin: 0
      text-align: center
  
  .amazon-logo
    height: 154px
    display: flex
    align-items: center
  
  #gift-card-amount.el-input__inner
    font-size: 15px
    font-weight: bold
</style>